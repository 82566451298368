import { defineStore } from 'pinia';
import axios from 'axios';

const useAdminStore = defineStore('admin', {

  state: () => ({
    api_url: 'https://payments-gate.com/api/v1',
    isLoggedIn: true,
    token: '',
    paymentMethodsEndpoint: '/payment_methods/',
    accessTokenEndpoint: '/access_token/',
    anyBankAdded: false,
    isSend: false,
    internetBankingActive: [],
    swiftActive: [],
    cryptoCurrencyActive: [],
    internetBanking: [],
    swift: [],
    cryptoCurrency: [],
  }),

  getters: {
    getInternetBanking: (state) => state.internetBanking,
    getSwift: (state) => state.swift,
    getCryptoCurrency: (state) => state.cryptoCurrency,
    getPaymentEndpoint: (state) => state.paymentMethodsEndpoint,
    getTokenEndpoint: (state) => state.accessTokenEndpoint,
    getAnyBankAdded: (state) => state.anyBankAdded,
    getToken: (state) => state.token,
  },

  actions: {
    urlSlashChecker(url) {
      const slash = '/';
      if (!url.endsWith(slash)) {
        // eslint-disable-next-line no-param-reassign
        url += slash;
      }
      return url;
    },

    async getBanks() {
      await axios.get(this.api_url + this.getPaymentEndpoint).then((response) => {
        this.internetBanking = response.data.internet_banking;
        this.swift = response.data.swift;
        this.cryptoCurrency = response.data.cryptocurrencies;
        this.anyBankAdded = this.internetBanking.length !== 0
          || this.swift.length !== 0
          || this.cryptoCurrency.length !== 0;
      });
    },

    async addBank(url, payload) {
      // eslint-disable-next-line no-param-reassign
      url = this.urlSlashChecker(url);
      await axios.post(this.api_url + this.getPaymentEndpoint + url, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        this.isSend = true;
        if (url === 'internet_banking/') {
          this.internetBanking.push(response.data);
        } else if (url === 'cryptocurrency/') {
          this.cryptoCurrency.push(response.data);
        } else {
          this.swift.push(response.data);
        }
      }).catch((error) => {
        if (error.response) {
          this.isSend = false;
        }
      });
      return this.isSend;
    },

    async deleteBank(url, id) {
      // eslint-disable-next-line no-param-reassign
      url = this.urlSlashChecker(url);
      await axios.delete(`${this.api_url + this.getPaymentEndpoint + url}delete/${id}`).then(() => {
        this.isSend = true;
        if (url === 'internet_banking/') {
          this.internetBanking = this.internetBanking.filter((e) => e.id !== id);
        } else if (url === 'cryptocurrency/') {
          this.cryptoCurrency = this.cryptoCurrency.filter((e) => e.id !== id);
        } else {
          this.swift = this.swift.filter((e) => e.id !== id);
        }
      }).catch(() => {
        this.isSend = false;
      });
      return this.isSend;
    },

    async updateBank(url, id, payload) {
      // eslint-disable-next-line no-param-reassign
      url = this.urlSlashChecker(url);
      await axios.put(`${this.api_url + this.getPaymentEndpoint + url}update/${id}`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        this.isSend = true;
        if (url === 'internet_banking/') {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.internetBanking.length; i++) {
            if (this.internetBanking[i].id === id) {
              this.internetBanking[i].country = response.data.country;
              this.internetBanking[i].bank_name = response.data.bank_name;
              this.internetBanking[i].account_name = response.data.account_name;
              this.internetBanking[i].branch_code = response.data.branch_code;
              this.internetBanking[i].account_number = response.data.account_number;
              this.internetBanking[i].iban = response.data.iban;
              this.internetBanking[i].currency = response.data.currency;
              this.internetBanking[i].comment = response.data.comment;
              this.internetBanking[i].is_active = response.data.is_active;
            }
          }
        } else if (url === 'cryptocurrency/') {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.cryptoCurrency.length; i++) {
            if (this.cryptoCurrency[i].id === id) {
              this.cryptoCurrency[i].crypto_name = response.data.crypto_name;
              this.cryptoCurrency[i].wallet_number = response.data.wallet_number;
              this.cryptoCurrency[i].qr_code = response.data.qr_code;
              this.cryptoCurrency[i].is_active = response.data.is_active;
            }
          }
        } else {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.swift.length; i++) {
            if (this.swift[i].id === id) {
              this.swift[i].account_owner = response.data.account_owner;
              this.swift[i].account_address = response.data.account_address;
              this.swift[i].currency = response.data.currency;
              this.swift[i].bank = response.data.bank;
              this.swift[i].bank_address = response.data.bank_address;
              this.swift[i].swift_code = response.data.swift_code;
              this.swift[i].account = response.data.account;
              this.swift[i].comment = response.data.comment;
              this.swift[i].is_active = response.data.is_active;
            }
          }
        }
      }).catch(() => {
        this.isSend = false;
      });
      return this.isSend;
    },

    appendBank(id, category) {
      if (category === 'İnternet Bankacılığı') {
        this.internetBankingActive.push(id);
      } else if (category === 'Kripto Paralar') {
        this.cryptoCurrencyActive.push(id);
      } else {
        this.swiftActive.push(id);
      }
    },

    removeBank(id, category) {
      if (category === 'İnternet Bankacılığı' || category === 'Internet Banking') {
        this.internetBankingActive = this.internetBankingActive.filter((e) => e !== id);
      } else if (category === 'Kripto Paralar' || category === 'Crypto Currencies') {
        this.cryptoCurrencyActive = this.cryptoCurrencyActive.filter((e) => e !== id);
      } else {
        this.swiftActive = this.swiftActive.filter((e) => e !== id);
      }
    },

    async generateToken(url, nameSurname) {
      const payload = {
        internet_banking: this.internetBankingActive,
        cryptocurrencies: this.cryptoCurrencyActive,
        swift: this.swiftActive,
        username: nameSurname,
      };
      await axios.post(this.api_url + this.getTokenEndpoint, payload).then((response) => {
        this.isSend = true;
        this.token = response.data.val;
      }).catch(() => {
        this.isSend = false;
      });
      return this.isSend;
    },
  },
});

export default useAdminStore;
