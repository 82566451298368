import { defineStore } from 'pinia';
import axios from 'axios';

const useDashboardStore = defineStore('dashboard', {

  state: () => ({
    api_url: 'https://payments-gate.com/api/v1',
    userName: '',
    endPoint: '/payment_methods',
    anyActiveBank: false,
    internetBankingActive: [],
    swiftActive: [],
    cryptoCurrencyActive: [],
    internetBanking: [],
    swift: [],
    cryptoCurrency: [],
    ibActiveFilteredBanks: [],
    ibPassiveFilteredBanks: [],
    cryptoActiveFilteredBanks: [],
    cryptoPassiveFilteredBanks: [],
    swiftActiveFilteredBanks: [],
    swiftPassiveFilteredBanks: [],
  }),

  getters: {
    getInternetBanking: (state) => state.internetBanking,
    getSwift: (state) => state.swift,
    getCryptoCurrency: (state) => state.cryptoCurrency,
    getEndpoint: (state) => state.endPoint,
    getUsername: (state) => state.userName,
    getIbActiveFilteredBanks: (state) => state.ibActiveFilteredBanks,
    getIbPassiveFilteredBanks: (state) => state.ibPassiveFilteredBanks,
    getCryptoActiveFilteredBanks: (state) => state.cryptoActiveFilteredBanks,
    getCryptoPassiveFilteredBanks: (state) => state.cryptoPassiveFilteredBanks,
    getSwiftActiveFilteredBanks: (state) => state.swiftActiveFilteredBanks,
    getSwiftPassiveFilteredBanks: (state) => state.swiftPassiveFilteredBanks,
  },

  actions: {
    urlSlashChecker(url) {
      const slash = '/';
      if (!url.endsWith(slash)) {
        // eslint-disable-next-line no-param-reassign
        url += slash;
      }
      return url;
    },

    async getActiveBanks(token) {
      // eslint-disable-next-line no-param-reassign
      token = this.urlSlashChecker(token);
      await axios.get(this.api_url + this.getEndpoint + token).then(async (response) => {
        this.anyBankAdded = true;
        this.internetBankingActive = response.data.internet_banking;
        this.cryptoCurrencyActive = response.data.cryptocurrencies;
        this.swiftActive = response.data.swift;
        this.userName = response.data.username;
        this.ibPassiveFilteredBanks = this.internetBanking
          // eslint-disable-next-line array-callback-return,consistent-return
          .filter((el) => {
            if (!this.internetBankingActive.includes(el.id) && el.is_active) {
              // eslint-disable-next-line no-param-reassign
              el.account_name = '**********';
              // eslint-disable-next-line no-param-reassign
              el.branch_code = '**********';
              // eslint-disable-next-line no-param-reassign
              el.account_number = '**********';
              // eslint-disable-next-line no-param-reassign
              el.iban = '**********';
              // eslint-disable-next-line no-param-reassign
              el.currency = '**********';
              // eslint-disable-next-line no-param-reassign
              el.comment = '**********';
              return el;
            }
          });
        this.ibActiveFilteredBanks = this.internetBanking
          .filter((el) => this.internetBankingActive.includes(el.id) && el.is_active);
        this.cryptoActiveFilteredBanks = this.cryptoCurrency.filter(
          (el) => this.cryptoCurrencyActive.includes(el.id) && el.is_active,
        );
        // eslint-disable-next-line consistent-return,array-callback-return
        this.cryptoPassiveFilteredBanks = this.cryptoCurrency.filter((el) => {
          if (!this.cryptoCurrencyActive.includes(el.id) && el.is_active) {
            // eslint-disable-next-line no-param-reassign
            el.wallet_number = '**********';
            // eslint-disable-next-line no-param-reassign
            el.comment = '**********';
            // eslint-disable-next-line no-param-reassign
            return el;
          }
        });
        this.swiftActiveFilteredBanks = this.swift.filter((el) => this.swift.includes(el.id)
          && el.is_active);
        // eslint-disable-next-line consistent-return,array-callback-return
        this.swiftPassiveFilteredBanks = this.swift.filter((el) => {
          if (!this.swiftActive.includes(el.id) && el.is_active) {
            // eslint-disable-next-line no-param-reassign
            el.account_owner = '**********';
            // eslint-disable-next-line no-param-reassign
            el.account_address = '**********';
            // eslint-disable-next-line no-param-reassign
            el.currency = '**********';
            // eslint-disable-next-line no-param-reassign
            el.bank = '**********';
            // eslint-disable-next-line no-param-reassign
            el.bank_address = '**********';
            // eslint-disable-next-line no-param-reassign
            el.account = '**********';
            // eslint-disable-next-line no-param-reassign
            el.swift_code = '**********';
            // eslint-disable-next-line no-param-reassign
            el.comment = '**********';
            // eslint-disable-next-line no-param-reassign
            return el;
          }
        });
      }).catch(() => {
        this.anyBankAdded = false;
      });
      return this.anyBankAdded;
    },

    async getAllBanks() {
      await axios.get(`${this.api_url + this.getEndpoint}/`).then((response) => {
        this.internetBanking = response.data.internet_banking.filter((el) => el.is_active);
        this.swift = response.data.swift.filter((el) => el.is_active);
        this.cryptoCurrency = response.data.cryptocurrencies.filter((el) => el.is_active);
      });
    },
    filterBanks(countryTag) {
      return this.internetBanking.filter((el) => el.country === countryTag);
    },
  },
});

export default useDashboardStore;
